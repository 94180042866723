import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

import ConstructionDetailHeader from './ConstructionDetailHeader';
import ConstructionDetailAside from './ConstructionDetailAside';
import ConstructionDetailContent from './ConstructionDetailContent';
import ConstructionDetailSchedule from './ConstructionDetailSchedule';
import ConstructionDetailEquipment from './ConstructionDetailEquipment';

const ConstructionDetail = () => {
  const { constructionId } = useParams();
  const [construction, setConstruction] = useState(null);

  useEffect(() => {
    const fetchConstruction = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/construction/${constructionId}`
        );
        setConstruction(response.data.construction);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania budowy');
      }
    };

    fetchConstruction();
  }, [constructionId]);

  if (!construction) {
    return (
      <Row className="align-items-center">
        <Col>
          <h5 className="mb-0">Szczegóły budowy</h5>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <ConstructionDetailHeader construction={construction} />
      <Row className="g-3">
        <Col lg={8}>
          <ConstructionDetailEquipment
            construction={construction}
            equipment={construction.assignedEquipment}
            setConstruction={setConstruction}
          />
          <ConstructionDetailSchedule construction={construction} />
          <ConstructionDetailContent construction={construction} />
        </Col>
        <Col lg={4}>
          <ConstructionDetailAside construction={construction} />
        </Col>
      </Row>
    </>
  );
};

export default ConstructionDetail;
