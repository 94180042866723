import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTaskStore } from '../stores/taskStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TaskBanner = ({ title, status, isLoading, onSubmit }) => {
  const { id } = useParams();
  const { edited, task } = useTaskStore();

  const handleSaveDraft = () => {
    onSubmit('draft');
  };

  const handlePublish = () => {
    onSubmit('pending');
  };

  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">
              {title}
              {task?.editedBy && (
                <div
                  className="d-inline-block my-0 text-secondary"
                  style={{ fontSize: '14px', marginLeft: '12px' }}
                >
                  Zadanie w trakcie edycji przez:{' '}
                  {`${task.editedBy?.firstName} ${task.editedBy?.lastName}`}
                  <FontAwesomeIcon style={{ marginLeft: '8px' }} icon="lock" />
                </div>
              )}
            </h5>
          </Col>
          <Col xs="auto">
            {(!id || status === 'draft') && (
              <Button
                size="sm"
                variant="falcon-default"
                className="me-2"
                type="submit"
                onClick={handleSaveDraft}
                disabled={isLoading || edited}
              >
                Zapisz szkic
              </Button>
            )}
            <Button
              size="sm"
              variant="falcon-primary"
              type="submit"
              onClick={handlePublish}
              disabled={isLoading || edited}
            >
              {id && status !== 'draft' ? 'Zapisz' : 'Opublikuj'}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

TaskBanner.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default TaskBanner;
