import React from 'react';
import { Row, Card, Placeholder } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TaskDetailGmap from './TaskDetailGmap';
import TaskDetailGallery from './TaskDetailGallery';
import { useTaskStore } from '../stores/taskStore';

const TaskDetailContent = () => {
  const { task } = useTaskStore();

  if (!task) {
    return (
      <Card>
        <Card.Body>
          <h5 className="fs-0 mb-3">Opis zadania</h5>
          <Placeholder as={Row} animation="glow" />
          <h5 className="fs-0 mt-5 mb-2 ">Kategorie</h5>
          <p>Brak kategorii</p>
        </Card.Body>
      </Card>
    );
  }

  const { description, categories, location, uploadedImages } = task;

  return (
    <Card>
      <Card.Body>
        <h5 className="fs-0 mb-3">Opis zadania</h5>
        {description}
        {categories.length > 0 && (
          <>
            <h5 className="fs-0 mt-5 mb-2">Kategorie</h5>
            {categories.map(category => (
              <Link
                key={category._id}
                to="#!"
                className="badge border link-secondary text-decoration-none me-1"
              >
                {category.name}
              </Link>
            ))}
          </>
        )}
        <TaskDetailGmap center={location.coordinates} />
        <h5 className="fs-0 mt-5 mb-2">Zdjęcia</h5>
        {uploadedImages?.length > 0 && (
          <TaskDetailGallery images={uploadedImages} />
        )}
      </Card.Body>
    </Card>
  );
};

export default TaskDetailContent;
