import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useState, useEffect } from 'react';

import ChecklistCustomFieldPreview from './ChecklistCustomFieldPreview';
import ChecklistCustomFieldEditable from './ChecklistCustomFieldEditable';
import IconItem from 'components/common/icon/IconItem';
import { useWatch } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

const ChecklistViewModeSwitch = ({
  viewMode,
  setViewMode,
  handleSave,
  handleAdd,
  fields
}) => {
  const isPreview = viewMode === 'preview';

  const handleChange = e => {
    e.preventDefault();
    if (isPreview) {
      setViewMode('edit');
      return;
    }

    setViewMode('preview');
  };

  return (
    <div className="d-flex align-items-center gap-2 justify-content-end">
      {isPreview ? (
        <IconItem
          tag="button"
          icon="pen-to-square"
          size="sm"
          className="btn rounded-3 fs--1 text-primary p-0"
          onClick={e => handleChange(e)}
        />
      ) : (
        <>
          <IconItem
            tag="button"
            icon="plus"
            size="sm"
            className="btn rounded-3 fs--1 text-primary p-0"
            cursor="pointer"
            onClick={handleAdd}
          />
          {fields ? (
            <IconItem
              tag="button"
              icon="floppy-disk"
              size="sm"
              className="btn rounded-3 fs--1 text-success p-0"
              onClick={e => handleSave(e)}
            />
          ) : (
            <IconItem
              tag="button"
              icon="eye"
              size="sm"
              className="btn rounded-3 fs--1 text-primary p-0"
              onClick={e => handleChange(e)}
            />
          )}
        </>
      )}
    </div>
  );
};

const ChecklistCustomField = ({
  register,
  unregister,
  control,
  setValue,
  checklistFields,
  onCustomFieldsChange
}) => {
  const [customFields, setCustomFields] = useState([]);
  const [viewMode, setViewMode] = useState('preview');

  const fields = useWatch({
    control,
    name: 'customFields'
  });

  const handleRemove = id => {
    const items = customFields.filter(fields => fields.id !== id);
    setCustomFields(items);
    onCustomFieldsChange(items);
    unregister(`customFields.${id}`);
  };

  const handleMoveUp = index => {
    if (index === 0) return;
    const fields = prevFields => {
      const newFields = [...prevFields];
      [newFields[index - 1], newFields[index]] = [
        newFields[index],
        newFields[index - 1]
      ];
      onCustomFieldsChange(newFields);
      return newFields;
    };
    setCustomFields(fields(customFields));
    setValue(
      'customFields',
      fields(customFields).reduce((acc, item) => {
        const { id, name, type, options } = item;
        return { ...acc, [id]: { name, type, options } };
      }, {})
    );
  };

  const handleMoveDown = index => {
    if (index === customFields.length - 1) return;
    const fields = prevFields => {
      const newFields = [...prevFields];
      [newFields[index + 1], newFields[index]] = [
        newFields[index],
        newFields[index + 1]
      ];
      onCustomFieldsChange(newFields);
      return newFields;
    };
    setCustomFields(fields(customFields));
    setValue(
      'customFields',
      fields(customFields).reduce((acc, item) => {
        const { id, name, type, options } = item;
        return { ...acc, [id]: { name, type, options } };
      }, {})
    );
  };

  const handleSave = e => {
    e.preventDefault();

    const items = Object.keys(fields).map(key => {
      const { name, type, options } = fields[key];
      return {
        id: key,
        name,
        type,
        options:
          options?.constructor === Array
            ? options
            : options?.length > 0
            ? options.split(', ').map(option => ({
                name: option.replace(/^!/, ''),
                valid: option.startsWith('!')
              }))
            : []
      };
    });
    setCustomFields(items);
    onCustomFieldsChange(items);
    unregister('customFields');
    setViewMode('preview');
  };

  const handleAdd = () => {
    const id = uuidv4();
    const newField = {
      id,
      name: '',
      type: 'text'
    };
    setCustomFields([...customFields, newField]);
    onCustomFieldsChange([...customFields, newField]);
  };

  useEffect(() => {
    if (checklistFields && checklistFields.length > 0) {
      setCustomFields(checklistFields);
    }
  }, [checklistFields]);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <h5 className="mb-0">Pola checklisty</h5>
        <ChecklistViewModeSwitch
          handleSave={handleSave}
          handleAdd={handleAdd}
          viewMode={viewMode}
          setViewMode={setViewMode}
          fields={fields}
        />
      </Card.Header>
      {customFields.length > 0 && (
        <Card.Body>
          {customFields.map((customField, index) => {
            return (
              <React.Fragment key={customField.id}>
                {viewMode == 'preview' ? (
                  <ChecklistCustomFieldPreview
                    name={customField.name}
                    type={customField.type}
                    options={customField.options}
                    register={register}
                  />
                ) : (
                  <ChecklistCustomFieldEditable
                    {...customField}
                    register={register}
                    index={index}
                    handleRemove={() => handleRemove(customField.id)}
                    handleMoveUp={() => handleMoveUp(index)}
                    handleMoveDown={() => handleMoveDown(index)}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Card.Body>
      )}
    </Card>
  );
};

ChecklistViewModeSwitch.propTypes = {
  viewMode: PropTypes.string.isRequired,
  setViewMode: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  fields: PropTypes.any
};

ChecklistCustomField.propTypes = {
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  checklistFields: PropTypes.array,
  onCustomFieldsChange: PropTypes.func.isRequired
};

export default ChecklistCustomField;
