import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TaskDetails from './TaskDetails';
import TaskStock from './TaskStock';
import TaskBanner from './TaskBanner';
import TaskUpload from './TaskUpload';
import { Col, Form, Row } from 'react-bootstrap';
import TaskOtherInfo from './TaskOtherInfo';
import axios from 'axios';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import useTranslation from 'hooks/useTranslation';
import LoadingSpinner from 'components/common/LoadingSpinner';
import AuthContext from 'context/auth/AuthContext';
import { useTaskStore } from '../stores/taskStore';

const CreateTask = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [stockItems, setStockItems] = useState([]);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null
  });
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const equipmentID = params.get('equipmentID');
  const equipmentName = params.get('equipmentName');
  const userID = params.get('userID');
  const userName = params.get('userName');

  const { edited, setEdited, setTask } = useTaskStore();

  const navigate = useNavigate();

  const defaultValues = {
    users: [],
    uploadedFiles: [],
    categories: []
  };

  const form = useForm({
    defaultValues
  });

  const locationId = useWatch({ control: form.control, name: 'locationId' });

  useEffect(() => {
    if (equipmentID && equipmentName) {
      form.setValue('equipment', { value: equipmentID, label: equipmentName });
    }
  }, [equipmentID, equipmentName, form.setValue]);

  useEffect(() => {
    if (userID && userName) {
      form.setValue('users', { value: userID, label: userName });
    }
  }, [userID, userName, form.setValue]);

  const fetchData = async () => {
    try {
      if (id) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tasks/${id}`
        );
        const responseTask = response.data.task;
        if (
          responseTask?.editedBy !== null &&
          responseTask?.editedBy?._id !== user._id
        ) {
          setEdited(true);
        } else {
          setEdited(false);
          putEdited(user._id);
        }

        setTask(responseTask);
        if (responseTask) {
          form.setValue('startDate', new Date(responseTask.startDate));
          form.setValue('endDate', new Date(responseTask.endDate));
          setDates({
            startDate: new Date(responseTask.startDate),
            endDate: new Date(responseTask.endDate)
          });
          form.setValue('name', responseTask.name);
          form.setValue('locationName', responseTask.locationName);
          form.setValue('city', responseTask.city);
          form.setValue('streetName', responseTask.streetName);
          form.setValue('zip', responseTask.zip);
          form.setValue('country', responseTask.country);
          form.setValue('description', responseTask.description);
          form.setValue('equipment', {
            value: responseTask.equipment._id,
            label: responseTask.equipment.name
          });
          form.setValue(
            'users',
            responseTask.users.map(user => ({
              value: user._id,
              label: user.firstName + ' ' + user.lastName
            }))
          );
          form.setValue('type', {
            value: responseTask.type,
            label: responseTask.type
          });
          form.setValue(
            'checklist',
            responseTask.checklist.map(checklist => ({
              value: checklist._id,
              label: checklist.name
            }))
          );
          form.setValue(
            'categories',
            responseTask.categories.map(category => ({
              value: category._id,
              label: category.name
            }))
          );
          responseTask.stockItems.forEach((item, index) => {
            form.setValue(`stockItems[${index}].name`, item.name);
            form.setValue(`stockItems[${index}].count`, item.count);
            form.setValue(`stockItems[${index}].checked`, item.checked);
          });
          setStockItems(responseTask.stockItems);
          form.setValue('privacy', responseTask.privacy);
          form.setValue('priority', responseTask.priority);
          form.setValue(
            'enableTaskNotifications',
            responseTask.enableTaskNotifications
          );
          form.setValue('uploadedFiles', '');
          setStatus(responseTask.status);
        }
      } else {
        setEdited(false);
      }
    } catch (error) {
      console.error('Failed to fetch task', error);
      toast.error('Błąd podczas pobierania zadania');
    }
  };

  const clearEdited = async () => {
    if (id) {
      await axios.put(`${process.env.REACT_APP_API_URL}/tasks/${id}`, {
        editedBy: '000000000000000000000000'
      });
      setEdited(false);
    }
  };

  const putEdited = async user => {
    await axios.put(`${process.env.REACT_APP_API_URL}/tasks/${id}`, {
      editedBy: user
    });
  };

  useEffect(() => {
    fetchData();
  }, [id, form.setValue]);

  useEffect(() => {
    if (!edited) {
      window.addEventListener('beforeunload', clearEdited);
      window.addEventListener('pagehide', clearEdited);
    }

    return () => {
      window.removeEventListener('beforeunload', clearEdited);
      window.removeEventListener('pagehide', clearEdited);
    };
  }, [edited]);

  useEffect(() => {
    return () => {
      if (!edited) {
        clearEdited();
      }
    };
  }, [edited]);

  const onSubmit = async data => {
    setIsLoading(true);

    if (locationId) {
      delete data.locationName;
      delete data.streetName;
      delete data.city;
      delete data.zip;
      delete data.country;
    }

    const formData = new FormData();

    for (let key in data) {
      if (key === 'categories') {
        if (data[key] && data[key].length > 0) {
          data[key].map(category => {
            formData.append('categories', category.value);
          });
        }
      } else if (key === 'stockItems') {
        formData.append('stockItems', JSON.stringify(data[key]));
      } else if (key === 'checklist') {
        formData.append('checklist', JSON.stringify(data[key]));
      } else if (key === 'equipment') {
        formData.append('equipment', data[key].value);
      } else if (key === 'locationId') {
        formData.append('locationId', data[key]);
      } else if (key === 'type') {
        formData.append('type', data[key].value);
      } else if (key === 'uploadedFiles') {
        for (let file of data[key]) {
          formData.append('uploadedFiles', file);
        }
      } else if (key === 'users') {
        data[key].map(user => {
          formData.append('users', user.value);
        });
      } else {
        formData.append(key, data[key]);
      }
    }

    formData.append('status', status);

    try {
      if (id) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/tasks/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/tasks/add`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
      }
      toast.success(id ? 'Zadanie zostało zapisane' : 'Zadanie zostało dodane');
    } catch (error) {
      console.error('Failed to create/update user', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
      navigate('/task-list', { replace: true });
      clearEdited();
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <FormProvider {...form}>
      <Form
        onSubmit={form.handleSubmit(onSubmit)}
        className="position-relative"
      >
        <Row className="g-3">
          <Col xs={12}>
            <TaskBanner
              title={id ? 'Edytuj zadanie' : 'Dodaj nowe zadanie'}
              status={status}
              isLoading={isLoading}
              onSubmit={setStatus}
            />
          </Col>
          <Col lg={8}>
            <TaskDetails dates={dates} />
            <TaskStock stockItems={stockItems} setStockItems={setStockItems} />
            {!edited && <TaskUpload />}
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar mb-3">
              <TaskOtherInfo />
            </div>
          </Col>
          <Col>
            <TaskBanner
              title="Dobra robota! Zadanie jest prawie gotowe."
              isLoading={isLoading}
              onSubmit={setStatus}
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CreateTask;
