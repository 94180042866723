import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import React, { useEffect } from 'react';
import { Button, Card, Form, FormControl, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { useTaskStore } from '../stores/taskStore';
import { useFormContext } from 'react-hook-form';

const TaskStockItem = ({
  name,
  count,
  checked,
  id,
  register,
  handleRemove
}) => {
  const { edited } = useTaskStore();

  return (
    <tr>
      <td>
        <Form.Control
          size="sm"
          type="text"
          placeholder="Nazwa elementu"
          defaultValue={name} //set default value
          {...register(`stockItems[${id}].name`, { disabled: edited })} //Registering field
        />
      </td>
      <td>
        <FormControl
          type="number"
          placeholder="Ilość"
          defaultValue={count} //set default value
          aria-label="Ilość (z dwoma miejscami po przecinku)"
          step="0.01"
          {...register(`stockItems[${id}].count`, { disabled: edited })} //Registering field
        />
      </td>
      <td className="text-center align-middle">
        <Form.Check
          type="checkbox"
          id={`stockChecked${id + 1}`}
          defaultChecked={checked} //set default checked status
          {...register(`stockItems[${id}].checked`, { disabled: edited })} //Registering field
        />
      </td>
      {!edited && (
        <td className="text-center align-middle">
          <Button variant="link" size="sm" onClick={() => handleRemove(id)}>
            <FontAwesomeIcon className="text-danger" icon="times-circle" />
          </Button>
        </td>
      )}
    </tr>
  );
};

const TaskStock = ({ stockItems, setStockItems }) => {
  const { edited } = useTaskStore();
  const { register, setValue, reset, unregister } = useFormContext();

  // Change Stock
  const changeStock = (id, name, value) => {
    const updatedStockItems =
      name === 'checked'
        ? stockItems.map(ticket => ({ ...ticket }))
        : [...stockItems];
    const updatedStock = { ...stockItems[id], [name]: value };

    setStockItems([
      ...updatedStockItems.slice(0, id),
      updatedStock,
      ...updatedStockItems.slice(id + 1)
    ]);
  };

  // Remove Stock
  const removeStock = id => {
    setStockItems([...stockItems.slice(0, id), ...stockItems.slice(id + 1)]);
    unregister(`stockItems[${id}]`);
  };
  // Add Stock
  const handleAddStock = () => {
    setStockItems([...stockItems, { name: '', count: 0 }]);
  };

  // Reset stock items
  useEffect(() => {
    setStockItems([]);
  }, [reset]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Dodaj elementy z magazynu</Card.Header>
      <Card.Body className="bg-light">
        <SimpleBarReact>
          <Table className="bg-white mb-2 dark__bg-1100 mt-3" bordered>
            <thead>
              <tr className="fs--1">
                <th scope="col">Nazwa artykułu</th>
                <th scope="col">Ilość</th>
                <th scope="col">Do zwrotu</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="event-ticket-body">
              {stockItems.map((item, index) => (
                <TaskStockItem
                  {...item}
                  id={index}
                  key={index}
                  register={register}
                  setValue={setValue}
                  handleChange={changeStock}
                  handleRemove={removeStock}
                />
              ))}
            </tbody>
          </Table>
        </SimpleBarReact>

        {!edited && (
          <IconButton
            onClick={handleAddStock}
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
          >
            Dodaj przedmiot
          </IconButton>
        )}
      </Card.Body>
    </Card>
  );
};

TaskStock.propTypes = {
  stockItems: PropTypes.array,
  setStockItems: PropTypes.func
};
TaskStockItem.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  checked: PropTypes.bool,
  id: PropTypes.number.isRequired,
  register: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default TaskStock;
