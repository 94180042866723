import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

//TODO: move things here

const initialState = {
  edited: true,
  task: null,
  relatedTasks: []
};

export const useTaskStore = create()(
  devtools(set => ({
    ...initialState,
    setEdited: data => set({ edited: data }),
    setTask: data => set({ task: data }),
    setRelatedTasks: data => set({ relatedTasks: data })
  }))
);
