import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import EquipmentTableHeader from './ConstructionTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import useTranslation from 'hooks/useTranslation';
import Flex from 'components/common/Flex';
import useFormatDate from 'hooks/useFormatDate';

const columns = [
  {
    accessor: 'mpkSymbol',
    Header: 'MPK',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, mpkSymbol } = rowData.row.original;
      return (
        <>
          <Link to={`/construction/${_id}`}>
            <strong>MPK {mpkSymbol}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'name',
    Header: 'Nazwa budowy',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name, malfunction } = rowData.row.original;
      return (
        <>
          <Link
            className={malfunction ? 'text-warning' : ''}
            to={`/construction/${_id}`}
          >
            <strong>{name}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'startDate',
    Header: 'Data rozpoczęcia',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { startDate } = rowData.row.original;
      const [formattedStartDate] = useFormatDate(startDate);
      return formattedStartDate;
    }
  },
  {
    accessor: 'location.locationName',
    Header: 'Adres',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { locationName, streetName, city, zip, country } =
        rowData.row.original;
      return (
        <>{`${locationName}, ${streetName}, ${zip} ${city}, ${country} `}</>
      );
    }
  },
  {
    accessor: 'manager',
    Header: 'Osoba odpowiedzialna',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { manager } = rowData.row.original;
      return <>{`${manager.firstName} ${manager.lastName}`}</>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id } = rowData.row.original;
      const { handleDeleteClick, loadingRows } = rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/construction/${_id}`}>
              Szczegóły
            </Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item as={Link} to={`/create-construction/${_id}`}>
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const ConstructionList = ({ loadingRows }) => {
  const [constructionList, setConstructionList] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/construction?${queryParams}&deletedAt[in]`
      );
      setConstructionList(response.data.construction);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  const handleRevertClick = async id => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/construction/${id}`,
        {
          deletedAt: null
        }
      );
      setConstructionList([response.data.construction, ...constructionList]);
      toast.success('Budowa przywrócone pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  const handleDeleteClick = async id => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/construction/${id}`, {
        deletedAt: new Date()
      });

      setConstructionList(
        constructionList.filter(construction => construction._id !== id)
      );
      toast.success(
        <>
          <Flex direction="column" alignItems="start" width="100%">
            Budowa usunięte pomyślnie.
            <span
              className="p-0 link-dark text-decoration-underline"
              onClick={() => handleRevertClick(id)}
            >
              Cofnij
            </span>
          </Flex>
        </>,
        {
          theme: 'colored'
        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    if (clearSelect) {
      clearSelect();
    }

    fetchData();
  }, [queryParams]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleDeleteClick,
        loadingRows
      }))}
      data={constructionList !== false ? constructionList : []}
      selection
      sortable
      pagination
      perPage={25}
      setClearFunction={setClearSelect}
    >
      <Card className="mb-3">
        <Card.Header>
          <EquipmentTableHeader
            table
            setConstructionList={setConstructionList}
            constructionList={constructionList}
            setQueryParams={setQueryParams}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={constructionList.length}
            table
            rowsPerPageSelection
          />
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

ConstructionList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(ConstructionList);
