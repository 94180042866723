import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import MultiSelect from 'components/common/MultiSelect';
import { handleMultiple } from '../../../../../helpers/bulkAction';
import { ConstructionTransferFilters } from './ConstructionTransferFilters';
import { CSVLink } from 'react-csv';
import { useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import axios from 'axios';
import debounce from 'lodash';
import useTranslation from 'hooks/useTranslation';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import pl from 'date-fns/locale/pl';
import classNames from 'classnames';
import { ADMIN_ROLES } from 'constants/users';

const ConstructionTransferTableHeader = ({
  selectedRowIds,
  setTransferList,
  transferList,
  setQueryParams,
  user,
  fetchData
}) => {
  const [selectedAction, setSelectedAction] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [hasCustomTransferDate, setHasCustomTransferDate] = useState(false);
  const [customTransferDate, setCustomTransferDate] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hasFetchedOptions, setHasFetchedOptions] = useState(false);

  const [constructions, setConstructions] = useState(null);

  const [open, setOpen] = useState(false);
  const csvLink = useRef();
  const [items, setItems] = useState([]);

  const [destinationConstruction, setDestinationConstruction] = useState([]);

  const headers = [
    { label: 'Numer ID', key: 'equipmentId' },
    { label: 'Urządzenie niesprawne?', key: 'malfunction' },
    { label: 'Nazwa urządzenia', key: 'name' },
    { label: 'Numer katalogowy', key: 'catalogNumber' },
    { label: 'Numer seryjny', key: 'serialNumber' },
    { label: 'Numer inwentarzowy', key: 'inventoryNumber' },
    { label: 'Producent', key: 'manufacturer.name' }
  ];

  const handleApplyAction = () => {
    switch (selectedAction) {
      case 'delete':
        handleMultiple(
          selectedRowIds,
          'construction/deleteTransfer',
          transferList,
          setTransferList
        );
        break;
      case 'export':
        if (items.length < 0) return;
        csvLink?.current?.link?.click();
        break;
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setHasCustomTransferDate(false);
  };

  const handleSelectChange = selected => {
    setSelectedOptions(selected);
  };

  const handleDestinationConstructionChange = selected => {
    setDestinationConstruction(selected);
  };

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/equipment`
      );
      setOptions(
        response.data.equipment
          .filter(item => item.currentAssignment !== null)
          .map(item => ({
            value: item._id,
            label: `${item.equipmentId} | ${item.name}`,
            equipmentId: item.equipmentId,
            currentAssignment: item.currentAssignment
          })) || []
      );
      setHasFetchedOptions(true);
    } catch (error) {
      console.error('Failed to fetch options:', error);
    }
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const {
      value: equipmentId,
      currentAssignment: { construction, brigade }
    } = selectedOptions;
    const transferData = {
      equipmentId,
      originConstructionId: construction._id,
      originBrigadeId: brigade?._id,
      destinationConstructionId: destinationConstruction.value,
      transferDate:
        hasCustomTransferDate && customTransferDate
          ? dayjs(customTransferDate).format('YYYY-MM-DD')
          : null,
      status: 'admin_approved'
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/construction/transfers/add`,
        transferData
      );
      handleCloseModal();
      setSelectedOptions([]);
      fetchData();

      toast.success('Dodano transfer urządzenia', { theme: 'colored' });
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    if (isDropdownOpen && !hasFetchedOptions) {
      fetchOptions();
    }
  }, [isDropdownOpen, hasFetchedOptions]);

  useEffect(() => {
    const fetchConstructions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/construction`
        );
        setConstructions(response.data.construction);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania budowy');
      }
    };

    fetchConstructions();
  }, []);

  useEffect(() => {
    setItems(
      Object.keys(selectedRowIds).map(id => {
        const construction = transferList.find(item => item._id === id);

        return {
          ...construction
        };
      })
    );
  }, [selectedRowIds]);

  return (
    <>
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            Lista transferów
          </h5>
        </Col>
        <Col
          xs={8}
          sm="auto"
          className="ms-auto text-end ps-0 d-flex justify-content-end"
        >
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="filter"
            transform="shrink-3"
            className="mx-2"
            onClick={() => setOpen(!open)}
          >
            <span>Filtruj</span>
          </IconButton>
          {Object.keys(selectedRowIds).length > 0 && (
            <div className="d-flex">
              <Form.Select
                size="sm"
                aria-label="Działania masowe"
                value={selectedAction}
                onChange={e => setSelectedAction(e.target.value)}
              >
                <option value="">Działania masowe</option>
                <option value="delete">Usuń</option>
                <option value="export">Eksportuj</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
                onClick={handleApplyAction}
                disabled={!selectedAction}
              >
                Zastosuj
              </Button>

              {items.length >= 0 && (
                <CSVLink
                  data={items}
                  headers={headers}
                  filename={`transfery_${dayjs().format(
                    'DD-MM-YYYY_HH-mm'
                  )}.csv`}
                  className="hidden"
                  ref={csvLink}
                  target="_blank"
                />
              )}
            </div>
          )}
          {ADMIN_ROLES.includes(user.role) && (
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                onClick={handleShowModal}
                transform="shrink-3"
                className="ms-2"
              >
                <span className="d-none d-sm-inline-block ms-1">
                  Dodaj transfer
                </span>
              </IconButton>
            </div>
          )}
        </Col>
        <Col xs={12} className="mt-2">
          <ConstructionTransferFilters
            open={open}
            setQueryParams={setQueryParams}
            setItems={setTransferList}
          />
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Form onSubmit={e => debounce(handleSubmit(e))}>
          <Modal.Header closeButton>
            <Modal.Title>Nowy transfer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="equipment" className="mb-3">
              <Form.Label>Wybierz urządzenia</Form.Label>
              <MultiSelect
                closeMenuOnSelect={true}
                isMulti={false}
                clearable
                options={options}
                placeholder="Wyszukaj..."
                value={selectedOptions}
                onChange={handleSelectChange}
                onMenuOpen={handleDropdownOpen}
                isLoading={!hasFetchedOptions}
                loadingMessage={() => 'Ładowanie...'}
              />
            </Form.Group>
            {/* TODO: Develop handling of multiple transfers */}
            {selectedOptions.length !== 0 && (
              <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
                <p className="mb-2 fs--1">
                  Przenosisz <strong>{selectedOptions.label}</strong>
                  <br />
                  Aktualna lokalizacja:{' '}
                  <strong>
                    {selectedOptions.currentAssignment?.construction?.name}
                  </strong>{' '}
                  <br />
                  Aktualna brygada:{' '}
                  <strong>
                    {selectedOptions.currentAssignment?.brigade?.name ||
                      'Brak przypisanej brygady'}
                  </strong>
                </p>
                <Form.Group className="mb-3" controlId="categoryName">
                  <Form.Label>Budowa docelowa</Form.Label>
                  <MultiSelect
                    closeMenuOnSelect={true}
                    isMulti={false}
                    clearable
                    options={constructions
                      .filter(
                        construction =>
                          construction._id !==
                            selectedOptions.currentAssignment.construction
                              ._id && construction.deletedAt === null
                      )
                      .map(construction => ({
                        value: construction._id,
                        label: construction.name
                      }))}
                    placeholder="Wyszukaj..."
                    value={destinationConstruction}
                    onChange={handleDestinationConstructionChange}
                    required
                  />
                </Form.Group>
                <Button
                  size="sm"
                  variant="link"
                  className={classNames('p-0', {
                    'd-none': hasCustomTransferDate
                  })}
                  onClick={() =>
                    setHasCustomTransferDate(!hasCustomTransferDate)
                  }
                >
                  Ustaw datę transferu
                </Button>
                {hasCustomTransferDate && (
                  <Form.Group
                    controlId="customTransferDate"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Form.Label>Data transferu</Form.Label>
                    <DatePicker
                      selected={customTransferDate}
                      locale={pl}
                      dateFormat="dd/MM/yyyy"
                      onChange={newDate => {
                        setCustomTransferDate(newDate);
                      }}
                      customInput={
                        <CustomDateInput
                          formControlProps={{
                            placeholder: 'dd/mm/yyyy'
                          }}
                        />
                      }
                    />
                  </Form.Group>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Anuluj
            </Button>
            <Button variant="primary" type="submit">
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

ConstructionTransferTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  transferList: PropTypes.array,
  setTransferList: PropTypes.func,
  setQueryParams: PropTypes.func,
  user: PropTypes.object,
  fetchData: PropTypes.func
};

export default ConstructionTransferTableHeader;
