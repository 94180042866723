import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import TaskDetailAside from './TaskDetailAside';
import TaskDetailContent from './TaskDetailContent';
import TaskDetailHeader from './TaskDetailHeader';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTaskStore } from '../stores/taskStore';

const TaskDetail = () => {
  const { taskId } = useParams();
  const { setTask, task, setRelatedTasks } = useTaskStore();

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tasks/${taskId}`
        );
        setTask(response.data.task);
        setRelatedTasks(response.data.relatedTasks);
      } catch (error) {
        console.error(error);
        toast.error('Błąd podczas pobierania zadania');
      }
    };

    fetchTask();
  }, [taskId]);

  if (!task) {
    return (
      <Row className="align-items-center">
        <Col>
          <h5 className="mb-0">Szczegóły zadania</h5>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <TaskDetailHeader />
      <Row className="g-3">
        <Col lg={8}>
          <TaskDetailContent />
        </Col>
        <Col lg={4}>
          <TaskDetailAside />
        </Col>
      </Row>
    </>
  );
};

export default TaskDetail;
