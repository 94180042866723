import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Button,
  Placeholder,
  ListGroup
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { downloadFile } from 'helpers/downloadFile';

const GenerateReportDatepicker = ({ constructionId }) => {
  const datePickerRef = useRef(null);
  const today = dayjs();
  const mondayThisWeek = today.weekday(0);
  const sundayThisWeek = mondayThisWeek.add(6, 'day');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openDate, setOpenDate] = useState(today.toDate());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const closeDatePicker = () => {
    setIsCalendarOpen(false);
    datePickerRef.current.setOpen(false);
  };

  const generateReport = () => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

      closeDatePicker();

      const toastId = toast.loading('Generowanie raportu...', {
        autoClose: false,
        theme: 'colored',
        type: 'info'
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/construction/${constructionId}/costs`,
          {
            reportDateFrom: formattedStartDate,
            reportDateTo: formattedEndDate,
            includeSummary: false
          },
          {
            responseType: 'blob'
          }
        )
        .then(response => {
          const fileName = `Raport_kosztow_${formattedStartDate}_${formattedEndDate}.pdf`;
          const pdfBlob = new Blob([response.data], {
            type: 'application/pdf'
          });
          downloadFile(pdfBlob, fileName);

          toast.update(toastId, {
            render: 'Raport wygenerowany pomyślnie',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            theme: 'colored'
          });
        })
        .catch(error => {
          console.error('Error generating report:', error);

          toast.update(toastId, {
            render:
              useTranslation(error.response?.data?.message) ||
              'Błąd podczas generowania raportu',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            theme: 'colored'
          });
        })
        .finally(() => {
          setStartDate(null);
          setEndDate(null);
          datePickerRef.current.clear();
        });
    }
  };

  const handleLastMonth = () => {
    const lastMonth = dayjs().subtract(1, 'month').startOf('month');
    const endOfLastMonth = dayjs().subtract(1, 'month').endOf('month');

    setStartDate(lastMonth.toDate());
    setEndDate(endOfLastMonth.toDate());
    setOpenDate(endOfLastMonth.toDate());
  };

  const handleThisMonth = () => {
    const startOfMonth = dayjs().startOf('month');
    const endOfMonth = dayjs().endOf('month');

    setStartDate(startOfMonth.toDate());
    setEndDate(endOfMonth.toDate());
    setOpenDate(endOfMonth.toDate());
  };

  const handleLastWeek = () => {
    const mondayLastWeek = mondayThisWeek.subtract(7, 'day');
    const sundayLastWeek = mondayLastWeek.add(6, 'day');

    setStartDate(mondayLastWeek.toDate());
    setEndDate(sundayLastWeek.toDate());
    setOpenDate(sundayLastWeek.toDate());
  };

  const handleThisWeek = () => {
    setStartDate(mondayThisWeek.toDate());
    setEndDate(sundayThisWeek.toDate());
    setOpenDate(sundayThisWeek.toDate());
  };

  const handleLastYear = () => {
    const lastYearStart = dayjs().subtract(1, 'year').startOf('year');
    const lastYearEnd = dayjs().subtract(1, 'year').endOf('year');

    setStartDate(lastYearStart.toDate());
    setEndDate(lastYearEnd.toDate());
    setOpenDate(lastYearEnd.toDate());
  };

  const handleThisYear = () => {
    const yearStart = dayjs().startOf('year');
    const yearEnd = dayjs().endOf('year');

    setStartDate(yearStart.toDate());
    setEndDate(yearEnd.toDate());
    setOpenDate(yearEnd.toDate());
  };

  return (
    <DatePicker
      ref={datePickerRef}
      popperPlacement="bottom-start"
      showPopperArrow={false}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      openToDate={openDate}
      formatWeekDay={day => day.slice(0, 3)}
      calendarContainer={({ children }) => (
        <>
          <div
            className="react-datepicker react-datepicker__predefined-ranges"
            style={{
              background: 'var(--falcon-react-datepicker-bg)',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }}
          >
            <ListGroup variant="flush" className="react-datepicker__ranges ">
              <ListGroup.Item action onClick={handleThisWeek}>
                Bieżący tydzień
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLastWeek}>
                Ubiegły tydzień
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleThisMonth}>
                Bieżący miesiąc
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLastMonth}>
                Ubiegły miesiąc
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleThisYear}>
                Bieżący rok
              </ListGroup.Item>
              <ListGroup.Item action onClick={handleLastYear}>
                Ubiegły rok
              </ListGroup.Item>
            </ListGroup>
            <div className="position-relative">{children}</div>
          </div>
          <div
            className="d-flex flex-row justify-content-end gap-3 p-2 border border-300 border-top-0 "
            style={{
              background: 'var(--falcon-react-datepicker-bg)'
            }}
          >
            <Button
              variant="falcon-default"
              size="sm"
              onClick={closeDatePicker}
            >
              Anuluj
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={generateReport}
              disabled={!startDate || !endDate}
            >
              Pobierz raport
            </Button>
          </div>
        </>
      )}
      showPreviousMonths
      endDate={endDate}
      selectsRange
      dateFormat="MMM dd"
      className="form-control"
      onCalendarClose={() => {
        datePickerRef.current.clear();
        setIsCalendarOpen(false);
      }}
      shouldCloseOnSelect={false}
      onInputClick={() => {
        setIsCalendarOpen(!isCalendarOpen);
        datePickerRef.current.setOpen(!isCalendarOpen);
      }}
      customInput={
        <Button variant="falcon-primary" size="sm">
          Generuj raport
        </Button>
      }
    />
  );
};

GenerateReportDatepicker.propTypes = {
  constructionId: PropTypes.string.isRequired
};

const ConstructionDetailHeader = ({ construction }) => {
  const { _id } = construction;
  const navigate = useNavigate();

  const handleRevertClick = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/construction/${construction._id}`,
        {
          deletedAt: null
        }
      );
      toast.success('Budowa przywrócone pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      navigate('/construction-list');
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/construction/${construction._id}`,
        {
          deletedAt: new Date()
        }
      );

      toast.success(
        <>
          <Flex direction="column" alignItems="start" width="100%">
            Budowa usunięta pomyślnie.
            <span
              className="p-0 link-dark text-decoration-underline"
              onClick={handleRevertClick}
            >
              Cofnij
            </span>
          </Flex>
        </>,
        {
          theme: 'colored'
        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      navigate('/construction-list');
    }
  };

  if (!construction) {
    return (
      <Card className="mb-3">
        <Card.Body className="bg-light border-top">
          <Placeholder as={Row} animation="glow" />
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="p-0 mb-3">
        <Card.Body className="overflow-hidden">
          <Row className="flex-center">
            <Col>
              <Flex>
                <div className="ms-2 flex-1">
                  <h3 className="fs-1 text-capitalize mb-0">
                    <strong>{construction.mpkSymbol}</strong>{' '}
                    {construction.name}
                  </h3>
                </div>
              </Flex>
            </Col>
            <Col md="auto" className="mt-4 mt-md-0">
              <Flex>
                {construction.deletedAt ? (
                  <Button
                    variant="falcon-success"
                    size="sm"
                    className="px-4 px-sm-5"
                    onClick={handleRevertClick}
                  >
                    Przywróć
                  </Button>
                ) : (
                  <>
                    <div className="me-2">
                      {<GenerateReportDatepicker constructionId={_id} />}
                    </div>
                    <Button
                      as={Link}
                      to={`/create-construction/${_id}`}
                      className="me-2"
                      variant="falcon-default"
                      size="sm"
                      icon="share-alt"
                    >
                      Edytuj
                    </Button>
                    <Button
                      variant="falcon-danger"
                      size="sm"
                      className="px-4 px-sm-5"
                      onClick={handleDeleteClick}
                    >
                      Usuń
                    </Button>
                  </>
                )}
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

ConstructionDetailHeader.propTypes = {
  construction: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    mpkSymbol: PropTypes.string,
    name: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    equipment: PropTypes.array.isRequired,
    deletedAt: PropTypes.string
  }).isRequired
};

export default ConstructionDetailHeader;
