import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import classNames from 'classnames';
import axios from 'axios';
import { downloadFile } from 'helpers/downloadFile';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';

const columns = [
  {
    accessor: 'name',
    Header: 'Harmonogram'
  },
  {
    accessor: 'startDate',
    Header: 'Data rozpoczęcia',
    Cell: rowData => {
      return dayjs(rowData.row.original.startDate).format('YYYY-MM-DD');
    }
  },
  {
    accessor: 'endDate',
    Header: 'Data zakończenia',
    Cell: rowData => {
      return dayjs(rowData.row.original.endDate).format('YYYY-MM-DD');
    }
  },
  {
    Header: 'Działania',
    disableSortBy: true,
    headerProps: { className: 'pe-4 text-end' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { handleDownloadReport, loadingRows } = rowData.column;
      const { _id, startDate, endDate, name } = rowData.row.original;
      const isLoading = loadingRows[_id];

      return (
        <div className="d-flex align-items-center justify-content-end">
          <CardDropdown
            className={classNames({
              'text-primary': true,
              'pe-none': isLoading
            })}
            icon={isLoading ? 'spinner' : 'download'}
            iconUtilClass={
              isLoading ? 'fa-spin pointer-events-none' : undefined
            }
          >
            <div className="py-2">
              <Dropdown.Item
                as="button"
                onClick={() =>
                  handleDownloadReport(_id, startDate, endDate, name)
                }
              >
                Pobierz raport ogólny
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() =>
                  handleDownloadReport(_id, startDate, endDate, name, true)
                }
              >
                Pobierz raport szczegółowy
              </Dropdown.Item>
            </div>
          </CardDropdown>
        </div>
      );
    }
  }
];

const ConstructionDetailSchedule = ({
  construction,
  loadingRows,
  setRowLoading
}) => {
  const ITEMS_PER_PAGE = 10;
  const handleDownloadReport = async (
    rowId,
    startDate,
    endDate,
    name,
    includeSummary
  ) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    try {
      setRowLoading(rowId, true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/construction/${construction._id}/costs`,
        {
          reportDateFrom: startDate,
          reportDateTo: endDate,
          scheduleName: name,
          includeSummary
        },
        {
          responseType: 'blob'
        }
      );

      const fileName = `Raport_kosztow_${formattedStartDate}_${formattedEndDate}.pdf`;
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      downloadFile(pdfBlob, fileName);
    } catch (error) {
      toast.error(
        useTranslation(error.response?.data?.message) || 'Błąd pobierania pliku'
      );
      console.error(error);
    } finally {
      setRowLoading(rowId, false);
    }
  };

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        loadingRows,
        handleDownloadReport
      }))}
      data={construction?.schedule}
      sortable
      pagination
      perPage={ITEMS_PER_PAGE}
    >
      <Card className="mb-3">
        <FalconCardHeader title="Harmonogram prac" titleTag="h6" />
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-800 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              borderless: true,
              className: 'fs--2 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          {construction?.schedule.length > ITEMS_PER_PAGE && (
            <>
              <AdvanceTableFooter
                rowCount={construction?.schedule.length}
                table
                rowsPerPageSelection
              />
              <AdvanceTablePagination table />
            </>
          )}
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

ConstructionDetailSchedule.propTypes = {
  construction: PropTypes.object.isRequired,
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(ConstructionDetailSchedule);
