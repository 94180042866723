import { Card, Row, Col, Form } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import IconButton from 'components/common/IconButton';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import CustomDateInput from 'components/common/CustomDateInput';
import DatePicker from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import dayjs from 'dayjs';
import CustomButtonGroup from 'components/common/CustomButtonGroup';

export const ConstructionPeriod = ({
  item,
  control,
  setPeriods,
  handleRemove,
  handleMoveUp,
  handleMoveDown
}) => {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null
  });

  const periods = useWatch({ control, name: 'periods' });

  const {
    register,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      name: '',
      startDate: null,
      endDate: null
    }
  });

  useEffect(() => {
    if (item) {
      setValue('name', item.name);

      if (item.startDate && item.endDate) {
        setFormData({
          startDate: new Date(item.startDate),
          endDate: new Date(item.endDate)
        });
      }
    }
  }, [item]);

  const handleChange = (name, value) => {
    if (value) {
      setFormData({ ...formData, [name]: value });
      setValue(name, value);
      if (name === 'startDate' && !formData.endDate) {
        const endDate = dayjs(value).add(9, 'days').toDate();
        setFormData({ startDate: value, endDate });
        setValue('endDate', endDate);

        setPeriods(
          'periods',
          periods.map(period =>
            period._id === item._id
              ? { ...period, [name]: value, endDate }
              : period
          )
        );
        return;
      }
      setPeriods(
        'periods',
        periods.map(period =>
          period._id === item._id ? { ...period, [name]: value } : period
        )
      );
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body className="bg-default hover-actions-trigger">
        <CustomButtonGroup
          handleRemove={handleRemove}
          id={item._id}
          handleMoveDown={handleMoveDown}
          handleMoveUp={handleMoveUp}
        />
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="name">
              <Form.Label>Nazwa harmonogramu</Form.Label>
              <Form.Control
                type="text"
                name="name"
                {...register('name', {
                  required: 'To pole jest wymagane',
                  onChange: e => {
                    handleChange('name', e.target.value);
                  }
                })}
                placeholder="Nazwa harmonogramu"
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                Pole jest wymagane
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group
              controlId="startDate"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Form.Label>Data rozpoczęcia</Form.Label>
              <DatePicker
                selected={formData.startDate}
                locale={pl}
                dateFormat="dd/MM/yyyy"
                filterDate={date =>
                  !formData.endDate ||
                  dayjs(date).isSameOrBefore(dayjs(formData.endDate), 'days')
                }
                onChange={newDate => {
                  handleChange('startDate', newDate);
                }}
                customInput={
                  <CustomDateInput
                    isInvalid={!!errors.startDate}
                    errorMessage={errors.startDate?.message}
                    formControlProps={{
                      placeholder: 'd/m/y',
                      name: 'startDate',
                      ...register('startDate', {
                        required: 'Data rozpoczęcia jest wymagana'
                      })
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group
              controlId="endDate"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Form.Label>Data zakończenia</Form.Label>
              <DatePicker
                selected={formData.endDate}
                locale={pl}
                dateFormat="dd/MM/yyyy"
                filterDate={date =>
                  !formData.startDate ||
                  dayjs(date).isSameOrAfter(dayjs(formData.startDate), 'days')
                }
                onChange={newDate => {
                  handleChange('endDate', newDate);
                }}
                customInput={
                  <CustomDateInput
                    isInvalid={!!errors.endDate}
                    errorMessage={errors.endDate?.message}
                    formControlProps={{
                      placeholder: 'd/m/y',
                      name: 'endDate',
                      ...register('endDate', {
                        required: 'Data zakończenia jest wymagana'
                      })
                    }}
                  />
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ConstructionPeriod.propTypes = {
  item: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  setPeriods: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleMoveUp: PropTypes.func.isRequired,
  handleMoveDown: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export const ConstructionPeriods = () => {
  const { setValue, control } = useFormContext();
  const periods = useWatch({ control, name: 'periods' });

  const addPeriod = () => {
    setValue('periods', [
      ...periods,
      {
        _id: uuidv4(),
        name: '',
        startDate: null,
        endDate: null
      }
    ]);
  };

  const handleMoveUp = index => {
    const newPeriods = [...periods];
    if (index === 0) return;
    const temp = newPeriods[index];
    newPeriods[index] = newPeriods[index - 1];
    newPeriods[index - 1] = temp;
    setValue('periods', newPeriods);
  };

  const handleMoveDown = index => {
    const newPeriods = [...periods];
    if (index === newPeriods.length - 1) return;
    const temp = newPeriods[index];
    newPeriods[index] = newPeriods[index + 1];
    newPeriods[index + 1] = temp;
    setValue('periods', newPeriods);
  };

  const handleRemove = id => {
    const newPeriods = periods.filter(period => period._id !== id);
    setValue('periods', newPeriods);
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Harmonogram prac</Card.Header>
      <Card.Body className="bg-light">
        {periods ? (
          periods.map((item, index) => (
            <ConstructionPeriod
              item={item}
              key={index}
              control={control}
              setPeriods={setValue}
              index={index}
              handleRemove={handleRemove}
              handleMoveUp={() => handleMoveUp(index)}
              handleMoveDown={() => handleMoveDown(index)}
            />
          ))
        ) : (
          <>Brak dekad</>
        )}

        <IconButton
          onClick={addPeriod}
          variant="falcon-default"
          size="sm"
          icon="plus"
          transform="shrink-3"
        >
          Dodaj harmonogram
        </IconButton>
      </Card.Body>
    </Card>
  );
};
