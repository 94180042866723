import React from 'react';
import PropTypes from 'prop-types';

import FalconCardHeader from 'components/common/FalconCardHeader';
import { Card } from 'react-bootstrap';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Task from 'components/app/tasks/task-list/Task';

const Tasks = ({ cardTitle, tasks, ...rest }) => {
  return (
    <Card {...rest}>
      <FalconCardHeader title={cardTitle} light />
      <Card.Body className="fs--1 border-bottom">
        {tasks?.length > 0 ? (
          tasks.map((task, index) => (
            <Task
              key={task._id}
              details={task}
              isLast={index === tasks.length - 1}
            />
          ))
        ) : (
          <p>Brak aktualnych zadań dla tego urządzenia</p>
        )}
      </Card.Body>
      <FalconCardFooterLink
        title="Wszystkie zadania"
        to="/task-list"
        size="sm"
      />
    </Card>
  );
};

Tasks.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape(Event.propTypes))
};

export default Tasks;
