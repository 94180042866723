import React, { useState, useEffect } from 'react';
import ConstructionDetails from './ConstructionDetails';
import ConstructionUpload from './ConstructionUpload';
import ConstructionBar from './ConstructionBar';
import { Col, Form, Row } from 'react-bootstrap';
import ConstructionOtherInfo from './ConstructionOtherInfo';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { ConstructionPeriods } from './ConstructionPeriods';
import { constructionTypes } from './ConstructionOtherInfo';
import dayjs from 'dayjs';

const CreateConstruction = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('active');
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    periods: []
  };
  const [dates, setDates] = useState({
    startDate: null
  });

  const methods = useForm({
    defaultValues
  });

  const { setValue, control } = methods;
  const locationId = useWatch({ control, name: 'locationId' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/construction/${id}`
          );
          const construction = response.data.construction;
          const predefinedType = constructionTypes.find(
            type => type.value === construction.type
          );

          if (construction) {
            setValue('startDate', new Date(construction.startDate));
            setDates({
              startDate: new Date(construction.startDate)
            });
            setValue('mpkSymbol', construction.mpkSymbol);
            setValue('name', construction.name);
            setValue('description', construction.description);
            setValue('categories', construction.categories);
            setValue('uploadedFiles', '');
            setValue('locationName', construction.locationName);
            setValue('city', construction.city);
            setValue('streetName', construction.streetName);
            setValue('zip', construction.zip);
            setValue('country', construction.country);
            setValue('manager', {
              value: construction.manager._id,
              label:
                construction.manager.firstName +
                ' ' +
                construction.manager.lastName
            });
            setValue(
              'categories',
              construction.categories.map(category => ({
                value: category._id,
                label: category.name
              }))
            );
            setValue(
              'brigades',
              construction.brigades.map(brigade => ({
                value: brigade._id,
                label: brigade.name
              }))
            );

            if (predefinedType) {
              setValue('type', predefinedType);
            }

            if (construction.schedule) {
              setValue('periods', construction.schedule);
            }

            setStatus(construction.status || 'active');
          }
        }
      } catch (error) {
        console.error('Failed to fetch construction', error);
        toast.error('Błąd podczas pobierania budowy');
      }
    };

    fetchData();
  }, [id, methods.setValue]);

  const onSubmit = async data => {
    setIsLoading(true);

    if (locationId) {
      delete data.locationName;
      delete data.streetName;
      delete data.city;
      delete data.zip;
      delete data.country;
    }

    const formData = new FormData();
    for (let key in data) {
      if (key === 'categories') {
        if (data[key] && data[key].length > 0) {
          data[key].map(category => {
            formData.append('categories', category.value);
          });
        }
      } else if (key === 'brigades') {
        if (data[key] && data[key].length > 0) {
          data[key].map(brigade => {
            formData.append('brigades', brigade.value);
          });
        }
      } else if (key === 'uploadedFiles') {
        for (let file of data[key]) {
          formData.append('uploadedFiles', file);
        }
      } else if (key === 'periods') {
        const scheduleData = data[key].map(period => ({
          uuid: period.uuid,
          name: period.name,
          startDate: dayjs(period.startDate).format('YYYY-MM-DD'),
          endDate: dayjs(period.endDate).format('YYYY-MM-DD')
        }));
        formData.append('schedule', JSON.stringify(scheduleData));
      } else if (key === 'locationId') {
        formData.append('locationId', data[key].value);
      } else if (key === 'manager') {
        formData.append('manager', data[key].value);
      } else if (key === 'type') {
        const predefinedType = constructionTypes.find(
          type => type.value === data[key].value
        );
        if (predefinedType) {
          formData.append('type', predefinedType.value);
        }
      } else {
        formData.append(key, data[key]);
      }
    }
    formData.append('status', status);

    try {
      if (id) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/construction/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        toast.success('Budowa została zaktualizowana');
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/construction/add`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        toast.success(id ? 'Budowa została zapisana' : 'Budowa została dodana');
      }
    } catch (error) {
      console.error('Failed to create/update construction', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      setIsLoading(false);
      navigate('/construction-list', { replace: true });
    }
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="position-relative"
      >
        <Row className="g-3">
          <Col xs={12}>
            <ConstructionBar
              title={id ? 'Edytuj budowę' : 'Dodaj budowę'}
              setStatus={setStatus}
              isLoading={isLoading}
            />
          </Col>
          <Col lg={8}>
            <ConstructionDetails />
            <ConstructionPeriods />
            <ConstructionUpload />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar mb-3">
              <ConstructionOtherInfo dates={dates} />
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {id ? 'Zapisz budowę' : 'Dodaj budowę'}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CreateConstruction;
