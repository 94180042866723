export const sortByFullname = (nameA, nameB) => {
  if (!nameA && !nameB) return 0;
  if (!nameA) return 1;
  if (!nameB) return -1;

  const fullNameA = `${nameA.firstName || ''} ${nameA.lastName || ''}`
    .trim()
    .toLowerCase();
  const fullNameB = `${nameB.firstName || ''} ${nameB.lastName || ''}`
    .trim()
    .toLowerCase();

  return fullNameA.localeCompare(fullNameB);
};
