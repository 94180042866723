import CustomDateInput from 'components/common/CustomDateInput';
// import { timezones } from 'data/events/timezones';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import axios from 'axios';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';
import { Controller, useWatch, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useTaskStore } from '../stores/taskStore';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const TaskDetails = ({ dates }) => {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    regDate: null
  });
  const [locations, setLocations] = useState([]);
  const [locationsPrepared, setLocationsPrepared] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { edited } = useTaskStore();
  const { register, setValue, errors, control } = useFormContext();

  const selectedLocation = useWatch({ control, name: 'locationId' });

  useEffect(() => {
    if (dates) {
      setFormData({
        ...formData,
        startDate: dates?.startDate,
        endDate: dates?.endDate
      });
    }
  }, [dates]);

  const filterStartTime = time => {
    if (
      formData?.endDate &&
      formData?.startDate &&
      formData?.startDate?.toDateString() === formData?.endDate?.toDateString()
    ) {
      return time.getHours() < formData?.endDate.getHours();
    }
    return true;
  };

  const filterEndTime = time => {
    if (
      formData?.startDate &&
      formData?.endDate &&
      formData?.startDate?.toDateString() === formData?.endDate?.toDateString()
    ) {
      return time?.getHours() > formData?.startDate?.getHours();
    }
    return true;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/locations`
      );
      setLocations(response.data.locations);
      const responsePrepare = response.data.locations.map(({ _id, name }) => ({
        value: _id,
        label: name
      }));
      setLocationsPrepared(responsePrepare);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleLocationSelect = () => {
    if (selectedLocation) {
      const location = locations.find(
        location => location._id === selectedLocation.value
      );
      setValue('locationId', location._id);
      setValue('locationName', location.name);
      setValue('streetName', location.streetName);
      setValue('city', location.city);
      setValue('zip', location.zip);
      setValue('country', location.country);
      handleCloseModal();
    }
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">Szczegóły zadania</Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group controlId="name">
                <Form.Label>Nazwa zadania</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Nazwa zadania"
                  isInvalid={!!errors?.name}
                  {...register('name', {
                    required: 'Nazwa zadania jest wymagany',
                    disabled: edited
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.name && errors?.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group
                controlId="startDate"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Form.Label>Data rozpoczęcia</Form.Label>
                <DatePicker
                  selected={formData?.startDate}
                  showTimeSelect
                  filterDate={date =>
                    !formData?.endDate ||
                    dayjs(date).isSameOrBefore(dayjs(formData?.endDate), 'days')
                  }
                  filterTime={filterStartTime}
                  timeFormat="HH:mm"
                  locale={pl}
                  disabled={edited}
                  timeIntervals={60}
                  dateFormat="dd/MM/yyyy HH:mm"
                  defaultValue={formData?.startDate}
                  timeCaption="Czas:"
                  onChange={newDate => {
                    handleChange('startDate', newDate);
                    setValue('startDate', newDate);
                  }}
                  customInput={
                    <CustomDateInput
                      isInvalid={!!errors?.startDate}
                      errorMessage={errors?.startDate?.message}
                      disabled={edited}
                      formControlProps={{
                        ...register('startDate', {
                          required: 'Data rozpoczęcia jest wymagana'
                        }),
                        disabled: edited,
                        name: 'startDate',
                        placeholder: 'd/m/y HH:mm'
                      }}
                    />
                  }
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group
                controlId="endDate"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Form.Label>Data zakończenia</Form.Label>

                <DatePicker
                  selected={formData?.endDate}
                  showTimeSelect
                  filterDate={date =>
                    !formData?.startDate ||
                    dayjs(date).isSameOrAfter(
                      dayjs(formData?.startDate),
                      'days'
                    )
                  }
                  filterTime={filterEndTime}
                  timeFormat="HH:mm"
                  locale={pl}
                  timeIntervals={60}
                  dateFormat="dd/MM/yyyy HH:mm"
                  defaultValue={formData?.endDate}
                  timeCaption="Czas:"
                  onChange={newDate => {
                    handleChange('endDate', newDate);
                    setValue('endDate', newDate);
                  }}
                  customInput={
                    <CustomDateInput
                      isInvalid={!!errors?.endDate}
                      errorMessage={errors?.endDate?.message}
                      disabled={edited}
                      formControlProps={{
                        ...register('endDate', {
                          required: 'Data zakończenia jest wymagana'
                        }),
                        disabled: edited,
                        placeholder: 'd/m/y HH:mm',
                        name: 'endDate'
                      }}
                    />
                  }
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <div className="border-dashed border-bottom"></div>
            </Col>
            <Col md="6">
              <Form.Group controlId="locationName">
                <Form.Label>Miejsce zadania </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Miejsce zadania "
                  name="locationName"
                  isInvalid={!!errors?.locationName}
                  {...register('locationName', {
                    required: 'To pole jest wymagane',
                    disabled: edited
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.locationName && errors?.locationName.message}
                </Form.Control.Feedback>
                {!edited && (
                  <Button
                    size="sm"
                    variant="link"
                    className="p-0"
                    onClick={handleOpenModal}
                  >
                    Wybierz z katalogu
                  </Button>
                )}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="streetName">
                <Form.Label>Ulica</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ulica"
                  name="streetName"
                  isInvalid={!!errors?.streetName}
                  {...register('streetName', {
                    required: 'To pole jest wymagane',
                    disabled: edited
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.streetName && errors?.streetName.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="city">
                <Form.Label>Miasto</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Miasto"
                  name="city"
                  isInvalid={!!errors?.city}
                  {...register('city', { required: true, disabled: edited })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.city && errors?.city?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="zip">
                <Form.Label>Kod pocztowy</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Kod pocztowy"
                  name="zip"
                  isInvalid={!!errors?.zip}
                  {...register('zip', { required: true, disabled: edited })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.zip && errors?.zip?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="country">
                <Form.Label>Państwo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Państwo"
                  name="country"
                  {...register('country', {
                    disabled: edited
                  })}
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <div className="border-dashed border-bottom"></div>
            </Col>
            <Col md="6">
              <Form.Group controlId="reporterName">
                <Form.Label>Osoba zgłaszająca</Form.Label>
                <Form.Control
                  type="text"
                  name="reporterName"
                  placeholder="Imię i nazwisko"
                  {...register('reporterName', {
                    disabled: edited
                  })}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="reporterPhone">
                <Form.Label>Numer telefonu</Form.Label>
                <Form.Control
                  type="text"
                  name="reporterPhone"
                  placeholder="Numer telefonu"
                  {...register('reporterPhone', {
                    disabled: edited
                  })}
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <div className="border-dashed border-bottom"></div>
            </Col>
            <Col md="12">
              <Form.Group controlId="description">
                <Form.Label>Opis zadania</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={6}
                  name="description"
                  {...register('description', {
                    disabled: edited
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Wybierz lokalizację z katalogu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Flex className="flex-between-center">
                <Form.Label>Wybierz lokalizacje</Form.Label>
              </Flex>
              <Controller
                name="locationId"
                rules={{ required: 'To pole jest wymagane', disabled: edited }}
                render={({ ref, field }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    options={locationsPrepared}
                    placeholder="Wybierz lokalizacje..."
                    isInvalid={!!errors?.equipment}
                  />
                )}
                control={control}
              />
              {errors?.equipment && (
                <Form.Text className="text-danger">
                  {errors?.equipment?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleLocationSelect}>
            Wybierz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

TaskDetails.propTypes = {
  dates: PropTypes.object.isRequired
};
export default TaskDetails;
