import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Card, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import classNames from 'classnames';
import MultiSelect from 'react-select';
import Flex from 'components/common/Flex';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import Lottie from 'lottie-react';
import warningLight from 'assets/img/animated-icons/warning-light.json';
import AuthContext from 'context/auth/AuthContext';
import { pl } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import { ADMIN_ROLES } from 'constants/users';
import dayjs from 'dayjs';

const EquipmentDetailHeader = ({ equipment }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);

  const [constructions, setConstructions] = useState([]);
  const [destinationConstruction, setDestinationConstruction] = useState(null);
  const [hasCustomTransferDate, setHasCustomTransferDate] = useState(false);
  const [customTransferDate, setCustomTransferDate] = useState(new Date());

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleDestinationConstructionChange = selectedOption => {
    setDestinationConstruction(selectedOption);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/construction/transfers/add`,
        {
          equipmentId: equipment._id,
          originConstructionId: equipment.currentAssignment?.construction?._id,
          destinationConstructionId: destinationConstruction.value,
          transferDate: hasCustomTransferDate
            ? dayjs(customTransferDate).format('YYYY-MM-DD')
            : null
        }
      );
      toast.success('Transfer dodany pomyślnie');
    } catch (error) {
      console.error('Error adding transfer:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      handleCloseModal();
    }
  };

  const handleRevertClick = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/equipment/${equipment._id}`,
        {
          deletedAt: null
        }
      );
      toast.success('Urządzenie przywrócone pomyślnie');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      navigate('task-list');
    }
  };

  const handleDeleteClick = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/equipment/${equipment._id}`,
        {
          deletedAt: new Date()
        }
      );

      toast.success(
        <>
          <Flex direction="column" alignItems="start" width="100%">
            Urządzenie usunięte pomyślnie.
            <span
              className="p-0 link-dark text-decoration-underline"
              onClick={handleRevertClick}
            >
              Cofnij
            </span>
          </Flex>
        </>,
        {
          theme: 'colored'
        }
      );
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    } finally {
      navigate('task-list');
    }
  };

  useEffect(() => {
    const fetchConstructions = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/construction?_id[nin]=${equipment?.currentAssignment?.construction?._id}`
      );

      const constructionOptionsData = response.data.construction.map(
        ({ _id, name }) => ({
          value: _id,
          label: name
        })
      );
      setConstructions(constructionOptionsData);
    };
    if (equipment) {
      fetchConstructions();
    }
  }, [equipment]);

  if (!equipment)
    return (
      <Card className="p-0 mb-3">
        <Card.Body className="overflow-hidden">
          <Row className="flex-center">
            <Col>
              <Flex>
                <div className="fs--1 ms-2 flex-1">
                  <h5 className="fs-0 text-capitalize"></h5>
                  <p className="mb-0 text-capitalize"></p>
                </div>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );

  const { name, _id, malfunction } = equipment;

  return (
    <>
      <Card className="p-0 mb-3">
        <Card.Body className="overflow-hidden">
          <Row className="flex-center">
            <Col>
              <Flex>
                <div className="fs--1 ms-2 flex-1">
                  <h5
                    className={`d-flex fs-0 text-capitalize ${
                      malfunction && 'text-warning'
                    }`}
                  >
                    {malfunction && (
                      <Lottie
                        animationData={warningLight}
                        style={{
                          height: '32px',
                          width: '32px',
                          margin: '-7px 0'
                        }}
                      />
                    )}
                    {name}
                  </h5>
                  <p className="mb-0 text-capitalize">{_id}</p>
                </div>
              </Flex>
            </Col>
            <Col md="auto" className="mt-4 mt-md-0">
              <Flex>
                {equipment.deletedAt ? (
                  <Button
                    variant="falcon-success"
                    size="sm"
                    className="px-4 px-sm-5"
                    onClick={handleRevertClick}
                  >
                    Przywróć
                  </Button>
                ) : (
                  <>
                    {ADMIN_ROLES.includes(user.role) && (
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="me-2"
                        onClick={handleShowModal}
                      >
                        Dodaj transfer
                      </Button>
                    )}
                    <Button
                      variant="falcon-default"
                      size="sm"
                      className="me-2"
                      as={Link}
                      to={`/create-task?equipmentID=${_id}&equipmentName=${name}`}
                    >
                      Stwórz zadanie
                    </Button>
                    <Button
                      className="me-2"
                      variant="falcon-default"
                      size="sm"
                      icon="share-alt"
                      as={Link}
                      to={`/create-equipment/${_id}`}
                    >
                      Edytuj
                    </Button>
                    <Button
                      variant="falcon-danger"
                      size="sm"
                      className="px-4 px-sm-5"
                      onClick={handleDeleteClick}
                    >
                      Usuń
                    </Button>
                  </>
                )}
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Form onSubmit={e => handleSubmit(e)}>
          <Modal.Header closeButton>
            <Modal.Title>Nowy transfer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
              <p className="mb-2 fs--1">
                Przenosisz <strong>{equipment.name}</strong>
                <br />
                Aktualna lokalizacja:{' '}
                <strong>
                  {equipment.currentAssignment?.construction?.name}
                </strong>{' '}
                <br />
                Aktualna brygada:{' '}
                <strong>
                  {equipment.currentAssignment?.brigade?.name ||
                    'Brak przypisanej brygady'}
                </strong>
              </p>
              <Form.Group className="mb-3" controlId="categoryName">
                <Form.Label>Budowa docelowa</Form.Label>
                <MultiSelect
                  closeMenuOnSelect={true}
                  isMulti={false}
                  clearable
                  options={constructions}
                  placeholder="Wyszukaj..."
                  value={destinationConstruction}
                  onChange={handleDestinationConstructionChange}
                  required
                />
              </Form.Group>
              <Button
                size="sm"
                variant="link"
                className={classNames('p-0', {
                  'd-none': hasCustomTransferDate
                })}
                onClick={() => setHasCustomTransferDate(!hasCustomTransferDate)}
              >
                Ustaw datę transferu
              </Button>
              {hasCustomTransferDate && (
                <Form.Group
                  controlId="customTransferDate"
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Form.Label>Data transferu</Form.Label>
                  <DatePicker
                    selected={customTransferDate}
                    locale={pl}
                    dateFormat="dd/MM/yyyy"
                    onChange={newDate => {
                      setCustomTransferDate(newDate);
                    }}
                    customInput={
                      <CustomDateInput
                        formControlProps={{
                          placeholder: 'dd/mm/yyyy'
                        }}
                      />
                    }
                  />
                </Form.Group>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Anuluj
            </Button>
            <Button variant="primary" type="submit">
              Dodaj
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

EquipmentDetailHeader.propTypes = {
  equipment: PropTypes.object
};

export default EquipmentDetailHeader;
