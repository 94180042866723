import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import CardDropdown from 'components/common/CardDropdown';
import useFormatDate from 'hooks/useFormatDate';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import withRowLoading from 'components/common/advance-table/withRowLoading';
import ChecklistTableHeader from './ChecklistTableHeader';
import { sortByFullname } from '../../../../helpers/sortByFullname';

const columns = [
  {
    accessor: 'name',
    Header: 'Checklista',
    headerProps: { style: { width: '250px' }, className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { _id, name } = rowData.row.original;
      return (
        <>
          <Link to={`/checklist/${_id}`}>
            <strong>{name}</strong>
          </Link>
        </>
      );
    }
  },
  {
    accessor: 'createdBy',
    sortType: (rowA, rowB) => {
      const createdByA = rowA.original.createdBy;
      const createdByB = rowB.original.createdBy;
      return sortByFullname(createdByA, createdByB);
    },
    Header: 'Autor',
    headerProps: { style: { width: '250px' }, className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { createdBy } = rowData.row.original;
      if (!createdBy)
        return <span className="text-secondary">Brak informacji</span>;
      return (
        <Link to={`/user/${createdBy._id}`}>
          <strong>
            {createdBy.firstName} {createdBy.lastName}
          </strong>
        </Link>
      );
    }
  },
  {
    accessor: 'createdAt',
    Header: 'Data utworzenia',
    Cell: rowData => {
      const { createdAt } = rowData.row.original;
      const [formattedDate] = useFormatDate(createdAt, true);
      return <span>{formattedDate}</span>;
    }
  },
  {
    accessor: 'categories',
    Header: 'Kategorie',
    disableSortBy: true,
    Cell: rowData => {
      const { categories } = rowData.row.original;
      return (
        <>
          {categories.map(({ _id, name }, index) => (
            <Link key={_id} className="text-500" to={`/category/${_id}`}>
              {name}
              {index !== categories.length - 1 && ', '}
            </Link>
          ))}
        </>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id } = rowData.row.original;
      const { handleDeleteClick, loadingRows } = rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/checklist/${_id}`}>
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              href="#!"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const ChecklistCollection = ({ loadingRows, setRowLoading }) => {
  const [checklists, setChecklists] = useState([]);
  const [queryParams, setQueryParams] = useState([]);

  const handleDeleteClick = async id => {
    const confirmDelete = window.confirm(
      'Jesteś pewien że chcesz usunąć to zadanie? Tej operacji nie można cofnąć!'
    );
    if (confirmDelete) {
      setRowLoading(id, true);
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/tasks/checklists/${id}`
        );
        setChecklists(checklists => checklists.filter(c => c._id !== id));
        toast.success('Checklista została usunięta', {
          theme: 'colored'
        });
      } catch (error) {
        console.error(error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setRowLoading(id, false);
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/checklists?${queryParams}`
      );
      setChecklists(response.data.checklists);
    } catch (error) {
      console.error(error);
      toast.error(useTranslation(error.response?.data.message, 'pl'), {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    if (checklists.length > 0) return;

    fetchData();
  }, [queryParams, checklists]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleDeleteClick,
        loadingRows
      }))}
      data={checklists}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <ChecklistTableHeader
            table
            setChecklists={setChecklists}
            checklists={checklists}
            setQueryParams={setQueryParams}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={checklists.length}
            table
            rowsPerPageSelection
          />
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

ChecklistCollection.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(ChecklistCollection);
