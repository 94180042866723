import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import UserTableHeader from './UserTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import withRowLoading from 'components/common/advance-table/withRowLoading';

import axios from 'axios';
import { toast } from 'react-toastify';
import useTranslation from 'hooks/useTranslation';
import RoleBadge from 'components/common/RoleBadge';
import { sortByFullname } from 'helpers/sortByFullname';

const columns = [
  {
    accessor: 'name',
    Header: 'Imię',
    headerProps: { className: 'pe-1' },
    sortType: (rowA, rowB) => {
      return sortByFullname(rowA.original, rowB.original);
    },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id, firstName, lastName, avatar } = rowData.row.original;
      return (
        <Flex alignItems="center">
          {avatar.img ? (
            <Avatar src={avatar.img} size="xl" className="me-2" />
          ) : (
            <Avatar size="xl" name={avatar.name} className="me-2" />
          )}
          <div className="flex-1">
            <Link to={`/user/${id}`}>
              <h5 className="mb-0 fs--1">
                {firstName} {lastName}
              </h5>
            </Link>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'role',
    Header: 'Uprawnienia',
    Cell: rowData => {
      const { role } = rowData.row.original;
      return <RoleBadge role={role} />;
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: rowData => {
      const { email } = rowData.row.original;
      return <a href={`mailto:${email}`}>{email}</a>;
    }
  },
  {
    accessor: 'phone',
    Header: 'Telefon',
    Cell: rowData => {
      const { phone } = rowData.row.original;
      return <a href={`tel:${phone.replace(/\s/g, '')}`}>{phone}</a>;
    }
  },
  {
    accessor: 'address',
    Header: 'Adres',
    headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
    cellProps: { className: 'ps-5' }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { _id } = rowData.row.original;
      const { handleDeleteClick, loadingRows } = rowData.column;

      const isLoading = loadingRows[_id];

      return (
        <CardDropdown
          className={isLoading ? 'pe-none' : undefined}
          icon={isLoading ? 'spinner' : undefined}
          iconUtilClass={isLoading ? 'fa-spin pointer-events-none' : undefined}
        >
          <div className="py-2">
            <Dropdown.Item as={Link} to={`/user/${_id}`}>
              Szczegóły
            </Dropdown.Item>
            <Dropdown.Divider as="div" />
            <Dropdown.Item as={Link} to={`/create-user/${_id}`}>
              Edycja
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDeleteClick(_id)}
            >
              Usuń
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const UserList = ({ loadingRows, setRowLoading }) => {
  const [users, setUsers] = useState([]);
  const [queryParams, setQueryParams] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users?status[nin]=archived&${queryParams}`
      );
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Błąd podczas pobierania danych');
    }
  };

  const handleDeleteClick = async id => {
    let confirmation = window.confirm(
      'Czy na pewno chcesz usunąć tego użytkownika?'
    );

    if (confirmation) {
      try {
        setRowLoading(id, true);
        await axios.delete(`${process.env.REACT_APP_API_URL}/users/${id}`);
        toast.success('Usunięto użytkownika');
        setUsers(users.filter(equipment => equipment._id !== id));
      } catch (error) {
        console.error('Error deleting data:', error);
        toast.error(useTranslation(error.response?.data.message, 'pl'), {
          theme: 'colored'
        });
      } finally {
        setRowLoading(id, false);
      }
    }
  };

  useEffect(() => {
    if (clearSelect) {
      clearSelect();
    }

    if (users.length > 0) return;

    fetchData();
  }, [queryParams, users]);

  return (
    <AdvanceTableWrapper
      columns={columns.map(column => ({
        ...column,
        handleDeleteClick,
        loadingRows
      }))}
      data={users}
      selection
      sortable
      pagination
      perPage={10}
      setClearFunction={setClearSelect}
    >
      <Card className="mb-3">
        <Card.Header>
          <UserTableHeader
            table
            users={users}
            setUsers={setUsers}
            setQueryParams={setQueryParams}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={users.length}
            table
            rowsPerPageSelection
          />
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

UserList.propTypes = {
  loadingRows: PropTypes.object.isRequired,
  setRowLoading: PropTypes.func.isRequired
};

export default withRowLoading(UserList);
